<template>
  <div class="public-create">
    <div class="public-create-wrapper manage-wrapper">
      <div class="public-create-container public-container">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="创建课程" name="first">
            <div class="add-public-course">
              <el-form ref="form" :model="form" label-width="180px" :rules="rules">

                <el-form-item label="课程名称：" :required="true" prop="name">
                  <el-input v-model="form.name" maxlength="30" show-word-limit style="width:555px">
                  </el-input>
                </el-form-item>

                <el-form-item label="课程简介：" class="textarea">
                  <el-input type="textarea" resize="none" rows="6" maxlength="300" show-word-limit
                    v-model="form.courseExplain">
                  </el-input>
                </el-form-item>

                <el-form-item label="课程类型：">
                  <el-select v-model="form.courseTypeId" placeholder="请选择课程类型" style="width:555px">
                    <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="课程学科：">
                  <el-select v-model="form.subjectId" placeholder="请选择课程学科" style="width:555px">
                    <el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="年级：">
                  <el-select v-model="form.gradeId" placeholder="请选择年级" style="width:555px">
                    <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="主题封面：">
                  <UploadCover @uploadCover="handleCoverChange"></UploadCover>
                </el-form-item>

                <el-form-item label="授课老师：" prop="teacher">
                  <el-select v-model="form.teacherId" placeholder="请选择授课老师" filterable>
                    <el-option v-for="item in teacherList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="课程标签：">
                  <el-input v-model="form.courseLabel" maxlength="30" show-word-limit style="width:555px"></el-input>
                </el-form-item>

                <el-form-item label="课节附件：">
                  <uploadFile @uploadFile="handleFileChange" :name="'lessonIdentifiers'" :sourceType=2 />
                </el-form-item>

                <el-form-item label="视频：" :required="true">
                  <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifier'" :tips="{image:false,url:true}" :sourceType=1 />
                </el-form-item>

                <el-form-item label="能力标准">
                  <el-cascader v-model="form.powerValue" :options="powerList"
                    :props="{ multiple: true, label: 'title', value: 'id' }" @change="changeUsesVal" collapse-tags
                    filterable></el-cascader>
                </el-form-item>

                <el-form-item class="el-form-item-btns">
                  <el-button @click="$router.push('/home/manage/public')">取消</el-button>
                  <el-button type="primary" @click="onSubmit">确定</el-button>
                </el-form-item>

              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
  export default {
    name: "addclass",
    data() {
      return {
        activeName: "first",
        form: {},
        rules: {
          name: [{
            required: true,
            message: "请输入课程名称",
            trigger: "blur"
          }],
        },
        // powerValue: "", //能力标准默认选中
        gradeList: [],
        teacherList: [], //老师列表
        subjectList: [], //课程学科
        typeList: [], //课程类型
        powerList: [] //能力标准列表
      };
    },
    computed: {},
    components: {},
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      },
      handleCoverChange(data) {
        // console.log(data)
        this.form.cover = data.md5
      },
      handleFileChange(data) {
        // console.log(data);
        this.form[data.name] = data.fileMD5List;
        this.form.link = data.link;
      },
      //选择能力标准
      changeUsesVal(val) {
        this.form.targetIds = []
        if (!val) return
        // console.log("选择的能力标准", val);
        val.map((item) => {
          this.form.targetIds.push(item[item.length - 1]);
        })
        // console.log(this.form.targetIds);
      },
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (this.form.link) {
              this.commitForm()
              return
            }
            if (!this.form.videoIdentifier || this.form.videoIdentifier.length == 0) {
              this.$message({
                showClose: true,
                message: '未添加点播视频！',
                type: 'error'
              });
              return;
            }
            this.commitForm();
          }
        })
      },
      async commitForm() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        if (typeof (this.form.vodIdentifier) !== 'string') {
          this.form.videoIdentifier = this.form.videoIdentifier ? this.form.videoIdentifier[0] : '';
        }
        // console.log(this.form)
        let resData = await this.$Api.Course.createPublicCourse(this.form)
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$notify({
            title: "成功",
            message: "创建成功",
            type: "success"
          });
          setTimeout(() => {
            loading.close();
            this.$store.commit('getRefreshState', true);
            this.$store.commit('getCreateState', true);
            this.$router.push('/home/manage/public');
          }, 100);
        } else {
          loading.close();
          this.$notify.error({
            title: "错误",
            message: resData.msg
          });
        }
      },
      //获取年级列表 
      async getGradeList() {
        let resData = await this.$Api.Course.getGradeList()
        //  console.log('年级列表',resData)
        Vue.set(this.form, 'gradeId', resData.data[0].id)
        this.gradeList = resData.data;
      },
      //获取能力标准
      async getPowerlist() {
        let resData = await this.$Api.Form.getAllPower()
        this.powerList = resData.data;
      },
      //课程学科列表
      async getSubjectList() {
        let resData = await this.$Api.Course.SubjectList();
        // console.log('学科列表',resData)
        if (!resData.data) return
        Vue.set(this.form, 'subjectId', resData.data[0].id)
        this.subjectList = resData.data;
      },
      //课程类型列表
      async getTypeList() {
        let resData = await this.$Api.Course.TypeList();
        // console.log('课程类型',resData)
        if (!resData.data) return
        Vue.set(this.form, 'courseTypeId', resData.data[0].id)
        this.typeList = resData.data;
      },
      // 获取老师列表
      async getTeacherList() {
        this.teacherlist = [];
        let resData = await this.$Api.Course.getAllTeacher();
        // console.log('老师列表',resData)
        if (!resData.data) return
        Vue.set(this.form, 'teacherId', resData.data[0].id)
        this.teacherList = resData.data;
      },
      // 页面初始化
      init() {
        this.getPowerlist()
        this.getTeacherList();
        this.getSubjectList();
        this.getTypeList();
        this.getGradeList();
      }
    },
    mounted() {
      this.init()
    },
    activated() {
      this.init()
    },
    deactivated() {
      // this.$refs['form'].resetFields();
      this.form = {};
    }
  };
</script>

<style lang="less" scoped></style>